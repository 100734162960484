import { Box, Button, Stack, Typography, useTheme } from "@mui/material";
import activityCard from "../../../static/activityCard.png";
import microphoneIcon from "../../../static/svgs/microphone.svg";
import headphonesIcon from "../../../static/svgs/headphones.svg";

type ActivityCardProps = {
  activity: any;
  isNextActivity?: boolean;
};
const ActivityCard = ({
  activity,
  isNextActivity = false,
}: ActivityCardProps) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        borderRadius: 24,
        position: "relative",
        width: "fit-content",
        minWidth: 400,
      }}
    >
      <img src={activityCard} alt="" />
      <Box
        sx={{
          position: "absolute",
          bottom: 0,
          left: 0,
          width: "100%",
          padding: isNextActivity ? 0 : 2.5,
        }}
      >
        <Box
          sx={{
            backgroundColor: theme.palette.common.white,
            borderRadius: 4,
            py: 3,
            px: 4.5,
          }}
        >
          {isNextActivity && (
            <Stack direction="row" alignItems="center">
              <Typography
                sx={{ fontWeight: 600, color: theme.palette.grey[500] }}
              >
                {activity.duration / 60} min
              </Typography>
              <Stack ml={1} direction="row">
                {activity.requiresVideo && <img src={headphonesIcon} alt="" />}
                {activity.requiresAudio && <img src={microphoneIcon} alt="" />}
              </Stack>
            </Stack>
          )}
          <Typography
            variant={isNextActivity ? "h1" : "h2"}
            sx={{
              fontWeight: 500,
            }}
          >
            {activity.name}
          </Typography>
          {!isNextActivity && (
            <Button
              variant="contained"
              sx={{
                backgroundColor: theme.palette.common.black,
                mt: 2,
                borderRadius: "50px",
              }}
            >
              <Typography
                sx={{ color: theme.palette.common.white, fontWeight: 600 }}
              >
                See Results
              </Typography>
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default ActivityCard;
