import { useState } from "react";
import { Box, Button, Paper, Stack, Typography } from "@mui/material";
import MailCheckForm from "./MailCheckForm";
import PasswordCheckForm from "./PasswordCheckForm";
import bgImage from "../../static/bgImage.png";
import ellipse from "../../static/bgEllipse.png";
import authService from "services/authService";
import { useNavigate } from "react-router-dom";
import { accountUpdated, login } from "redux/slices/accountSlice";
import { useDispatch } from "react-redux";
import { signInWithRedirect, signOut } from "aws-amplify/auth";
// import { useCookies } from "react-cookie";
// import assessmentService from "services/assessmentService";

const SffLogin = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [email, setEmail] = useState<string>("");

  const [errorMessage, setErrorMessage] = useState<string>("");
  // const [cookies, setCookie] = useCookies(["authToken"]);

  const handleLogin = async (password: string) => {
    const data = {
      email: email,
      password: password,
    };

    try {
      setErrorMessage("");
      await authService.login(data);

      // const cookie: any = {
      //   httpOnly: false,
      //   maxAge: 86400 * 1000,
      //   domain: "ets.org",
      //   path: "/",
      //   sameSite: "None",
      //   secure: true,
      // };

      // let token = resp.access || resp.accessToken?.jwtToken;
      // console.log('Token=', token, cookies);
      // authService.setAuthToken(token);
      // setCookie('authToken', token, cookie);

      const account = await authService.loadAccount();
      dispatch(login(account));
      dispatch(accountUpdated(account));
      navigate("/");
    } catch (e) {
      console.log("Error=", e);
      setErrorMessage(
        `Incorrect password or email. Please, check your credentials`
      );
    }
  };

  const handleLoginWithAWS = async () => {
    try{
    await signInWithRedirect({});
    } catch(e) {
      console.log('Error', e);
      await signOut();
      await signInWithRedirect({});
    }
  };

  return (
    <Stack
      direction="row"
      alignItems="center"
      gap={15}
      sx={{ height: "100%", paddingLeft: 14 }}
    >
      <Typography fontSize={77} width={370}>
        Building the Future of Skills.
      </Typography>
      <Stack
        direction="column"
        sx={{ justifyContent: "center", height: "100%" }}
      >
        <Box width="560px" sx={{ position: "relative", alignSelf: "center" }}>
          <Box
            sx={{
              transform: "translate(-10%, -60%)",
              position: "absolute",
              zIndex: 1,
              width: "765px",
              height: "460px",
              mixBlendMode: "multiply",
            }}
          >
            <img src={ellipse} alt="" />
          </Box>
          <Box
            sx={{
              position: "absolute",
              transform: "translate(0, -85%)",
              height: 372,
              width: "100%",
            }}
          >
            <img src={bgImage} alt="" />
          </Box>
          <Box sx={{ position: "absolute", zIndex: 2 }}>
            <Paper
              sx={{
                textAlign: "center",
                padding: 6,
                borderRadius: "24px",
              }}
            >
              <Typography variant="h3" sx={{ mx: 15 }}>
                Sign in to access your account.
              </Typography>
              {/* <Box sx={{ mt: 4 }}>
                {!email ? (
                  <MailCheckForm callback={setEmail} />
                ) : (
                  <PasswordCheckForm callback={handleLogin} />
                )}
              </Box> */}

              <Box sx={{ mt: 4 }}>
                  <Button 
                    variant="contained" 
                    fullWidth
                    sx={{ bgcolor: "black", color: "white", borderRadius: 50 }}
                    onClick={handleLoginWithAWS}
                  >
                    Sign In
                  </Button>
              </Box>

              <Typography sx={{ mt: 4, color: "red" }}>
                {errorMessage && <div>{errorMessage}</div>}
              </Typography>
            </Paper>
          </Box>
        </Box>
      </Stack>
    </Stack>
  );
};

export default SffLogin;
