import { CircularProgress } from "@mui/material";
import AppDisplay from "AppDisplay";
import { useCallback, useEffect, useLayoutEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { accountUpdated } from "redux/slices/accountSlice";
import authService from "services/authService";
import { saveDataToLocalStorage } from "utils";
import { signIn, getCurrentUser, fetchAuthSession} from 'aws-amplify/auth';
import {Amplify} from 'aws-amplify';

const awsExports = {
  aws_project_region: process.env.REACT_APP_AWS_REGION,
  aws_cognito_identity_pool_id: process.env.REACT_APP_IDENTITY_POOL_ID,
  aws_cognito_region: process.env.REACT_APP_AWS_REGION,
  aws_user_pools_id: process.env.REACT_APP_USERPOOL_ID,
  aws_user_pools_web_client_id: process.env.REACT_APP_USERPOOL_WEB_CLIENT_ID,
  oauth: {
    domain: process.env.REACT_APP_OAUTH_DOMAIN, 
    scope: process.env.REACT_APP_OAUTH_SCOPES?.split(','),
    redirectSignIn: process.env.REACT_APP_OAUTH_REDIRECT_SIGN_IN, 
    redirectSignOut: process.env.REACT_APP_OAUTH_REDIRECT_SIGN_OUT,
    responseType: process.env.REACT_APP_OAUTH_RESPONSE_TYPE
  },
};

console.log(awsExports);

Amplify.configure(awsExports);

const App = () => {
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();

  const handleLogin = useCallback(async () => {
    try {
      //Get logged in user from AWS
      const authUser = await getCurrentUser();
      console.log('authUser=',authUser);
      
      //Get logged in session from AWS
      const authSession = await fetchAuthSession();
      console.log(JSON.stringify(authSession));
      const cognitoToken = authSession.tokens?.accessToken.toString();
      if(!cognitoToken) {
        throw new Error('cognitoToken is undefined');
      }

      // logging into MTC with the AWS token
      const loggedInResp = await authService.loggedInWithCognitoToken({cognitoToken: cognitoToken});
      console.log('loggedInResp',loggedInResp);

      //Load logged user account from MTC backend
      const account = await authService.loadAccount();

      // save to redux
      dispatch(accountUpdated(account));

      if (window.location.pathname.includes("/login")) {
        window.location.href = "/";
      }
    } catch (e) {
      savePath();
    }
  }, [dispatch]);

  const onLoginEvent = useCallback(
    async (event: StorageEvent) => {
      if (event.key === "login-event") {
        setIsLoading(true);
        await handleLogin();
        setIsLoading(false);
      }
    },
    [handleLogin]
  );

  const onLogoutEvent = (event: StorageEvent) => {
    if (event.key === "logout-event") {
      window.location.href = "/";
    }
  };

  const savePath = () => {
    const { pathname, search } = window.location;
    if (!pathname.includes("login")) {
      saveDataToLocalStorage("redirectUrl", "pathname", pathname + search);
    }
  };

  useEffect(() => {
    const run = async () => {
      window.addEventListener("storage", onLogoutEvent);
      window.addEventListener("storage", onLoginEvent);

      await handleLogin();

      setIsLoading(false);
    };

    run();

    return () => {
      window.removeEventListener("storage", onLogoutEvent);
      window.removeEventListener("storage", onLoginEvent);
    };
  }, [handleLogin, onLoginEvent]);

  return isLoading ? <CircularProgress /> : <AppDisplay />;
};
export default App;
