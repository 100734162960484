import { createTheme, responsiveFontSizes } from "@mui/material/styles";

let config = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  palette: {
    primary: {
      main: "#157CB9",
      light: "#28A9E1",
    },
    text: {
      primary: "#212529",
      secondary: "#495057",
      disabled: "#ADB5BD",
    },
    secondary: {
      main: "#19857b",
    },
  },
});

config = createTheme(config, {
  palette: {
    text: {
      green: "#4CAF50",
    },
    error: {
      main: "#D32F2F",
    },
    star: {
      main: "#FF9800",
    },
    success: {
      main: "#2E7D32",
      light: "#4CAF50",
    },
    gray: {
      50: "#F6FaFD",
      100: "#F2F4F6",
      200: "#E9ECEF",
      300: "#DEE2E6",
      400: "#CED4DA",
      500: "#ADB5BD",
      600: "#6C757D",
      700: "#495057",
      800: "#343A40",
      900: "#212529",
    },
  },
  typography: {
    h1: {
      fontSize: "2.5rem",
      fontWeight: 500,
    },
    h2: {
      fontSize: "2rem",
      fontWeight: 500,
    },
    h3: {
      fontSize: "1.75rem",
      fontWeight: 500,
    },
    h4: {
      fontSize: "1.5rem",
    },
    h5: {
      fontSize: "1.25rem",
    },
    h6: {
      fontSize: "1.125rem",
    },
    body2: {
      [config.breakpoints.down("sm")]: {
        fontSize: "0.875rem",
      },
    },
    body3: {
      fontSize: "0.875rem",
      [config.breakpoints.down("sm")]: {
        fontSize: "0.75rem",
      },
    },
    subtitle1: {
      fontSize: "1.125rem",
      [config.breakpoints.down("sm")]: {
        fontSize: "1rem",
        lineHeight: "1.2rem",
      },
    },
    subtitle2: {
      fontSize: "1rem",
      [config.breakpoints.down("sm")]: {
        fontSize: "0.75rem",
        lineHeight: "0.75rem",
      },
    },
    button: {
      textTransform: "none",
    },
  },
});

config = responsiveFontSizes(config);

export default config;
