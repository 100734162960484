import { Box, Stack, Typography, useTheme } from "@mui/material";
import { useLocation } from "react-router-dom";
import { StyledLinkBox } from "./styles";

import artifact from "../../../static/svgs/artifact.svg";
import badgeCheck from "../../../static/svgs/badge-check.svg";
import home from "../../../static/svgs/home.svg";
import trophy from "../../../static/svgs/trophy.svg";

const Navigation = ({
  handleNavigate,
}: {
  handleNavigate: (id: "home" | "activities" | "badges" | "artifact") => void;
}) => {
  const theme = useTheme();
  const location = useLocation();

  const links: {
    tag: "home" | "activities" | "badges" | "artifact";
    icon: any;
  }[] = [
    { tag: "home", icon: home },
    { tag: "activities", icon: trophy },
    { tag: "badges", icon: badgeCheck },
    { tag: "artifact", icon: artifact },
  ];

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.common.white,
        height: "100%",
        px: 1,
        pt: 3,
        textAlign: "center",
      }}
    >
      <Typography variant="h4">sff</Typography>
      <Stack mt={8} gap={3}>
        {links.map((link) => (
          <StyledLinkBox
            key={link.tag}
            isActive={link.tag === location.hash.slice(1)}
            onClick={() => handleNavigate(link.tag)}
          >
            <img src={link.icon} alt={`${link.tag}`} />
          </StyledLinkBox>
        ))}
      </Stack>
    </Box>
  );
};

export default Navigation;
