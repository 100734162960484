import { Provider } from "react-redux";
import { ReactNode } from "react";
import store from "redux/store";

type AppProvidersProps = { children: ReactNode };

function AppProviders({ children }: AppProvidersProps) {
  return <Provider store={store}>{children}</Provider>;
}

export { AppProviders };
