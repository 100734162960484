import BaseService from "services/baseService";
import FetchService from "services/fetchService";

class AssessmentService extends BaseService {
  servicePath = "assessment-modules";

  getModules(userId: string) {
    return FetchService.get<any>({
      url: this.getUrl(),
      data: { userId },
      needAuth: true,
    });
  }

  getStatus(userId: string) {
    return FetchService.get<any>({
      url: this.getUrl("status"),
      data: { userId },
    });
  }

  async getModulesWithStatus(userId: string) {
    let modules = await this.getModules(userId);
    let statuses:any = await this.getStatus(userId);

    let statusesArr = (statuses?.data || []);

    let statusMap: Map<string, any> = new Map();
    for (let i = 0; i < statusesArr.length; i++) {
      const element = statusesArr[i];
      statusMap.set(element.id, element);      
    }

    (modules || []).map((module:any) => {
      let key;
      if (statusMap.has(module.id)) {
        key = module.id;
      }
      else if(statusMap.size > 0) {
        let result = statusMap.entries().next();
        key = result.value?.[0];
      }
      if(key) {
        module.status = statusMap.get(key);
        statusMap.delete(key);
      }
    });

    console.log('modules==',modules);
    return modules;
  }
}

export default new AssessmentService();
