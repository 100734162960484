import ActivityCard from "../ActivityCard";
import { Box, IconButton, Stack, Typography, useTheme } from "@mui/material";
import primaryLarge from "../../../static/svgs/primaryLarge.svg";
import { ReactComponent as VectorLogo } from "../../../static/svgs/vector.svg";

type NextActivityProps = {
  activity: any;
};
const NextActivity = ({ activity }: NextActivityProps) => {
  const theme = useTheme();
  return (
    <Box
      position="relative"
      height="325px"
      display="flex"
      flexDirection="column"
      justifyContent="flex-end"
    >
      <Box sx={{ position: "absolute", left: 36, top: 0, zIndex: 1 }}>
        <ActivityCard activity={activity} isNextActivity />
      </Box>
      <Box
        sx={{
          position: "absolute",
          flex: 1,
          top: 64,
          borderRadius: 6,
          height: "80%",
          width: "100%",
          backgroundColor: "rgba(77, 211, 254, 1)",
          overflow: "hidden",
        }}
      >
        <VectorLogo
          fill="rgba(145, 228, 255, 0.67)"
          style={{ position: "absolute", right: 0, width: "100%" }}
        />
        <Box sx={{ position: "absolute", right: 40, top: 36, width: "40%" }}>
          <Stack direction="row" gap={1} flexWrap="wrap">
            {activity.skills.map((skill: any) => (
              <Typography
                key={skill.skillId}
                component="span"
                sx={{
                  backgroundColor: theme.palette.common.white,
                  borderRadius: "50px",
                  py: 1.5,
                  px: 1,
                }}
              >
                {skill.skillId}
              </Typography>
            ))}
          </Stack>
        </Box>
        <IconButton sx={{ position: "absolute", right: 24, bottom: 24 }}
          href={activity.launchURL} target="_blank"
        >
          <img src={primaryLarge} alt="" />
        </IconButton>
      </Box>
    </Box>
  );
};

export default NextActivity;
