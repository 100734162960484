import {
  Alert,
  Box,
  CircularProgress,
  Grid2,
  Stack,
  Typography,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import Navigation from "./Navigation";
import activities from "../../mock/data.json";
import ActivityCard from "./ActivityCard";
import NextActivity from "./NextActivity";
import { useNavigate } from "react-router-dom";
import Header from "./Header";
// import assessmentService from "services/assessmentService";
import { useSelector } from "react-redux";
import { getCurrentUser } from "lib/cognito";
import assessmentService from "services/assessmentService";

const BasePage = () => {
  const user = useSelector((state: any) => state.account);

  const navigate = useNavigate();
  const intersectionElementRef = useRef();

  const [isLoading, setIsLoading] = useState(true);
  const [assessments, setAssessment] = useState<any>();

  useEffect(() => {
    const fetchAssessments = async () => {
      setIsLoading(true);
      console.log('user=',user);
      // const assessments = await activities;
      const assessments = await assessmentService.getModulesWithStatus(user.id);
      setAssessment(assessments);
      setIsLoading(false);
    };

    fetchAssessments();
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            navigate(`/#${entry.target.id}`);
          }
        });
      },
      { rootMargin: "0px 0px -100%", root: intersectionElementRef.current }
    );

    Array.from(document.querySelectorAll("div[id]"))
      .filter((section) => section.id !== "root")
      .forEach((section) => observer.observe(section));

    return () => observer.disconnect();
  }, [navigate]);

  const handleNavigate = (
    id: "home" | "activities" | "badges" | "artifact"
  ) => {
    const target = document.getElementById(id);
    // Instant scrolling is a compromise between intersection observer and link navigation.
    // If set to ‘smooth’, the intersection watcher will be triggered and the url will flicker
    target?.scrollIntoView({ behavior: "auto" });
  };

  console.log("isLoading: ", isLoading);

  return (
    <Stack
      direction="row"
      flexWrap="nowrap"
      overflow="hidden"
      height="100%"
      sx={{ backgroundColor: "rgba(254, 253, 251, 1)" }}
    >
      <Box sx={{ height: "100%", width: "fit-content", flex: 0 }}>
        <Navigation handleNavigate={handleNavigate} />
      </Box>
      <Box flex={1} height="100%" display="flex" flexDirection="column">
        {/* Header */}
        <Header user={user} />
        <Box
          ref={intersectionElementRef}
          sx={{ overflow: "auto", height: "100%", position: "relative" }}
        >
          {/* Activities section */}
          <Box
            px={5}
            pt={5}
            id="activities"
            sx={{ minHeight: "100vh", maxWidth: 1912 }}
          >
            {isLoading ? (
              <CircularProgress />
            ) : (
              <>
                <Typography>Continue on your Next Activity</Typography>
                <Box mt={3}>
                  {assessments.length>0 && <NextActivity activity={assessments[0]} />}
                </Box>
                <Box mt={7}>
                  <Typography>All Activities</Typography>
                  <Grid2 container spacing={2} columns={2} sx={{ mt: 2 }}>
                    {(assessments || []).map((activity: any) => (
                      <ActivityCard key={activity.id} activity={activity} />
                    ))}
                  </Grid2>
                </Box>
              </>
            )}
          </Box>
          {/* Badges section */}
          <Box id="badges" mt={5} px={5}>
            <Box sx={{ backgroundColor: "aliceblue", minHeight: "100vh" }}>
              <Alert severity="info">Badges section is under development</Alert>
            </Box>
          </Box>
          {/* Scroll(?) section */}
          <Box id="artifact" mt={5} px={5}>
            <Box sx={{ backgroundColor: "aliceblue", minHeight: "100vh" }}>
              <Alert severity="info">This section is under development</Alert>
            </Box>
          </Box>
        </Box>
      </Box>
    </Stack>
  );
};

export default BasePage;
