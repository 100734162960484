import { Box } from "@mui/material";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import Login from "./pages/login/index";
import ThemeRegistry from "./theme/registry";
import BasePage from "pages/base";
import ProtectedRoute from "components/ProtectedRoute";
import NotFoundPage from "pages/notFound";

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <ProtectedRoute>
        <BasePage />
      </ProtectedRoute>
    ),
    errorElement: <NotFoundPage />,
  },
  { path: "/login", element: <Login /> },
]);

const AppDisplay = () => {
  return (
    <ThemeRegistry>
      <Box sx={{ height: "100vh", minWidth: 968, overflow: "hidden" }}>
        <RouterProvider router={router} />
      </Box>
    </ThemeRegistry>
  );
};

export default AppDisplay;
